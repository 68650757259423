import { useEffect, useRef } from 'react';
import anime from 'animejs';
import './Tech.scss';
import tech from './img/tech.jpg';

function Tech() {
  const techRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets:
                '.Tech h2, .Tech p, .Tech__steps__step, .Tech__points li',
              translateY: [500, 0],
              duration: 3000,
              easing: 'easeOutExpo',
              delay: anime.stagger(100),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (techRef.current) {
      observer.observe(techRef.current);
    }

    return () => {
      if (techRef.current) {
        observer.unobserve(techRef.current);
      }
    };
  }, []);
  return (
    <div className="Tech" ref={techRef}>
      <div className="Tech__title" style={{ backgroundImage: `url(${tech})` }}>
        <h2>ТЕХНОЛОГІЯ ВОЛОГОГО ОЧИЩЕННЯ ЕЛЕКТРОТЕХНІЧНОГО ОБЛАДНАННЯ</h2>
        <p>
          ДІЕЛЕКТРИЧНИМИ БАГАТОКОМПОНЕНТНИМИ ОЧИЩУВАЛЬНИМИ СУМІШАМИ{' '}
          <span>ECOFINEX</span>
        </p>
      </div>
      <div className="Tech__steps">
        <div className="Tech__steps__step">
          <span>1</span>
          <div className="Tech__steps__step__description">
            <h4>Розпорошення </h4>
            <p>
              діелектричних очищувачів на монтаж та елементну базу обладнання
            </p>
          </div>
        </div>
        <div className="Tech__steps__step">
          <span>2</span>
          <div className="Tech__steps__step__description">
            <h4>Очищувачі </h4>
            <p>
              розм’якшують, розчиняють навіть десятирічні забруднення, видаляють
              оксидно-сульфідні плівки, не ушкоджуючи при цьому ізоляцію,
              контакти, пластмаси та інші матеріали
            </p>
          </div>
        </div>
        <div className="Tech__steps__step">
          <span>3</span>
          <div className="Tech__steps__step__description">
            <h4>Видалення </h4>
            <p>забруднень та нанесеного очищувача з обладнання</p>
          </div>
        </div>
      </div>
      <div className="Tech__points">
        <ul>
          <li>
            Не вимагає відключення електрообладнання та виведення його з
            експлуатації під напругою та понад 1000В
          </li>
          <li>
            Покращує показники опору ізоляції у тому числі на обладнанні, що
            працює у вологому середовищі
          </li>
          <li>Знижує ризик виникнення пожежі</li>
          <li>
            Не вимагає розбирання обладнання механізмів - очищення можливе в
            важкодоступних місцях
          </li>
          <li>
            Дозволяє відновити працездатність обладнання після пожеж та аварій
          </li>
          <li>
            Продовжує термін служби обладнання на 20-30%, мінімізує
            ремонтно-експлуатаційні витрати
          </li>
          <li>
            Зменшує енерговтрати, усуває струми витоку та знижує поверхневий
            опір
          </li>
          <li>Знижує ризик появи коротких замикань</li>
        </ul>
      </div>
    </div>
  );
}

export default Tech;
