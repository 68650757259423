import { useEffect } from 'react';
import anime from 'animejs';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef } from 'react';
import 'swiper/css';
import './Products.scss';
import productsData from '../products.js';
import arrowLeft from './img/icons/Vector-1.svg';
import arrowRight from './img/icons/Vector.svg';

function Products() {
  const productsRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets:
                '.Products h2, .Products__carousel__slide img, .Products__carousel__slide__property',
              translateY: [500, 0],
              duration: 3000,
              easing: 'easeOutExpo',
              delay: anime.stagger(100),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (productsRef.current) {
      observer.observe(productsRef.current);
    }

    return () => {
      if (productsRef.current) {
        observer.unobserve(productsRef.current);
      }
    };
  }, []);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="Products" ref={productsRef}>
      <h2>Продукти EcoFinex</h2>
      <Swiper
        ref={swiperRef}
        slidesPerView={1}
        navigation
        loop
        autoHeight
        className="Products__carousel"
      >
        {productsData.map((product, index) => (
          <SwiperSlide key={index} className="Products__carousel__slide">
            <img src={product.photo} alt={`Slide ${index + 1}`} />
            <div className="Products__carousel__slide__property">
              <h4>властивості</h4>
              <p>{product.properties}</p>
            </div>
            <div className="Products__carousel__slide__property">
              <h4>сфера застосування</h4>
              <p>{product.usage}</p>
            </div>
          </SwiperSlide>
        ))}
        <div className="Products__carousel__arrows">
          <img
            src={arrowRight}
            alt="Previous Slide"
            className="swiper-button-prev arrow"
            onClick={handlePrevSlide}
          />
          <img
            src={arrowLeft}
            alt="Next Slide"
            className="swiper-button-next arrow"
            onClick={handleNextSlide}
          />
        </div>
      </Swiper>
    </div>
  );
}

export default Products;
