import Hero from './Hero';
import Use from './Use';
import About from './About';
import Eco from './Eco';
import Tech from './Tech';
import Products from './Products';
import Contact from './Contact';

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Use />
      <About />
      <Eco />
      <Tech />
      <Products />
      <Contact />
    </div>
  );
}

export default Home;
