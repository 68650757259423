import anime from 'animejs';
import './Hero.scss';
import hero from './img/hero.jpg';
import { useEffect } from 'react';

const Hero = () => {
  useEffect(() => {
    anime({
      targets: [
        '.Hero h2',
        '.Hero img',
        '.Hero__block__part1',
        '.Hero__block__part2',
        '.Hero__block__part3',
      ],
      translateY: [200, 0],
      duration: 1500,
      easing: 'easeOutExpo',
      delay: anime.stagger(100), // delay increases by 500ms for each element
    });
  }, []);
  return (
    <div className="Hero">
      <h2>
        БЕЗКОНТАКТНЕ ОЧИЩЕННЯ ДІЕЛЕКТРИЧНИМИ СУМІШАМИ БЕЗ ВІДКЛЮЧЕННЯ
        ЕЛЕКТРОУСТАНОВОК
      </h2>
      <img src={hero} alt="Безконтактне очищення" />
      <div className="Hero__block">
        <span className="Hero__block__part1">ВИДАЛЯЄ ДО</span>{' '}
        <h1 className="Hero__block__part2">98% </h1>
        <span className="Hero__block__part3">всіх існуючих забруднень</span>
      </div>
    </div>
  );
};

export default Hero;
