import { useEffect, useRef } from 'react';
import anime from 'animejs';
import './About.scss';
import Button from '../components/Button';
import about from './img/about.jpg';
import { scrollTo } from '../utils/scrollTo';

const About = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets:
                '.About img, .About__block h3, .About__block p, .About__columns li, .About button',
              translateY: [500, 0],
              duration: 2000,
              easing: 'easeOutExpo',
              delay: anime.stagger(200),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);
  return (
    <div className="About" ref={aboutRef}>
      <img src={about} alt="Електростанція" />
      <div className="About__block">
        <h3>ECOFINEX ЦЕ</h3>
        <p>
          СЕРВІСНО-ВИРОБНИЧЕ ПІДПРИЄМСТВО, ЩО ВИРОБЛЯЄ ЕКОЛОГІЧНІ ДІЕЛЕКТРИЧНІ
          МИЮЧІ СУМІШІ ТА НАДАЄ КОМПЛЕКС ПОСЛУГ З ОЧИЩЕННЯ ЕЛЕКТРОУСТАТКУВАННЯ
          ВІД ЗАБРУДНЕНЬ БУДЬ-ЯКОГО ХАРАКТЕРУ ТА СКЛАДНОСТІ
        </p>
      </div>
      <ul className="About__columns">
        <li>
          <h4>Експерти №1 в Україні</h4>по вологому очищенню електроустаткування
          діелектричними сумішами
        </li>
        <li>
          <h4>Маємо необхідні ліцензії</h4>та відповідні допуска з
          електробезпеки
        </li>
        <li>
          <h4>100% українські суміші-очисники</h4>власного виробництва, які
          відповідають вимогам законодавства України
        </li>
        <li>
          <h4>Інноваційна запатентована технологія</h4>отримала високу оцінку
          профільних інститутів
        </li>
        <li>
          <h4>Власна лабораторія</h4>дозволяє підібрати індивідуальну формулу
          суміші під специфічні забруднення замовника
        </li>
        <li>
          <h4>Працюємо по всій Україні</h4>та стрімко розширюємо
          дистриб’юторську/ дилерську мережу
        </li>
      </ul>

      <Button
        buttonText="звʼязатися з компанією"
        onClick={() => scrollTo('contact')}
      />
    </div>
  );
};

export default About;
