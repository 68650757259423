import anime from 'animejs';
import './Header.scss';
import Button from './Button';
import logo from './img/logo_ecofinex.png';
import { useEffect } from 'react';
import { scrollTo } from '../utils/scrollTo';

function Header({ onClick }) {
  useEffect(() => {
    anime({
      targets: [
        '.Header__logo img',
        '.Header__contact a',
        '.Header__contact button',
      ],
      translateY: [-200, 0],
      duration: 1500,
      easing: 'easeOutExpo',
      delay: anime.stagger(100), // delay increases by 500ms for each element
    });
  }, []);
  return (
    <header className="Header" id="top">
      <div className="Header__logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="Header__contact">
        <a href="tel:+380440000000">044 000 00 00</a>
        <Button
          buttonText="зв'язатися"
          onClick={() => {
            scrollTo('contact');
          }}
        />
      </div>
    </header>
  );
}

export default Header;
