import BC from "./Homepage/img/products/BC.jpg";
import EC from "./Homepage/img/products/EC.jpg";
import ES from "./Homepage/img/products/ES.jpg";
import HC from "./Homepage/img/products/HC.jpg";
import MG from "./Homepage/img/products/MG.jpg";
import OG from "./Homepage/img/products/OG.jpg";
import PL from "./Homepage/img/products/PL.jpg";

const products = [
  {
    name: "HC",
    photo: HC,
    properties:
      "Високоефективний очисник. Діелектрик, застосовується як самостійно, так і в складі композиції із засобами попередньої обробки. Має високі антистатичні властивості, можливо застосування в умовах роботи обладнання під напругою до 1000В і більше. Не розчиняється у воді.",
    usage:
      "Застосовується для очищення механізмів та електрообладнання всіх типів і видів, наприклад: генераторів, електричних машин, комутуючих та розподільчих пристроїв, елементів мікроелектроніки, друкованих плат, та іншого силового та сигнального електрообладнання.",
  },
  {
    name: "ES",
    photo: ES,
    properties:
      "Високоефективний, діелектричний, не горючий очисник. Швидко випаровується, біорозкладається. Якісно розчиняє сильні вуглецеві відкладення, оксидні та сульфідні плівки, згустки олії та мастил, окисли металів — після чого швидко випаровується без залишку, має високі антистатичні властивості. Можливе застосування в умовах роботи обладнання під напругою до 1000В та понад. Не розчиняється у воді.",
    usage:
      "Застосовується на виробництвах у виняткових випадках, де до складів є вимоги щодо пожежної безпеки “НЕ ГОРЮЧИЙ”. Використовується для очищення електротехніки та електромеханіки, електричних та електронних установок, комутаційного обладнання, пристроїв мікроелектроніки та телекомунікації без демонтажу та розбирання. Застосовується у всіх видах промисловості, включаючи харчову та фармацевтичну.",
  },
  {
    name: "OG",
    photo: OG,
    properties:
      "Діелектричний очисник попередньої обробки. Якісно видаляє оксидно-сульфідні плівки з поверхонь контактів будь-якого типу, розчиняє масляні та жирові відкладення та забруднення, відокремлює їх від поверхонь. Можливе застосування в умовах роботи обладнання під напругою до 1000В і більше. Не розчиняється у воді.",
    usage:
      "Застосовується для попереднього очищення (тільки у складі композиції OG + HC або OG + ES) механізмів та електроустаткування всіх типів та видів, наприклад: електричних машин, комутуючих та розподільчих пристроїв, елементів мікроелектроніки та іншого силового та сигнального електрообладнання. Очищувач нейтральний для металів, ізоляції, пластику, еластомерів та інших матеріалів.",
  },
  {
    name: "EC",
    photo: EC,
    properties:
      "Високоефективний діелектричний очищуючий концентрат. Швидко проникає і розчиняє вуглецеві відкладення, затверділі олії та мастила, оксидні та сульфідні плівки, сажу, прибирає нагари, а також відокремлює від поверхні бруд і пил, у тому числі графітовий. Може пошкодити деякі види фарб, лаків, пластику та ізоляції, написів на технологічних бирках. Рекомендуємо перевірити матеріали на сумісність перед застосуванням. Можливе застосування в умовах роботи обладнання під напругою до 1000В і більше. Не розчиняється у воді.",
    usage:
      "Застосовується для попередньої обробки (тільки у складі композиції EC+HC або EC+ES). Використовується для очищення силового електрообладнання, електродвигунів та двигунів внутрішнього згоряння, теплообмінників, масляних радіаторів, ланцюгів, шестерень, контакторів та інших металевих елементів. Очисник може пошкодити деякі види фарб, лаків, пластику та ізоляції. Рекомендуємо перевірити матеріали на сумісність перед застосуванням.",
  },
  {
    name: "MG",
    photo: MG,
    properties:
      "Діелектричний консервант технічних пристроїв. Призначений для загального превентивного захисту електричного обладнання, контактних груп, вводів-виводів технічних пристроїв та установок від впливу води та вологи. Має високу діелектричну міцність 27кВ/мм.",
    usage:
      "У перехідний та зимовий період захищає від коротких замикань внаслідок перепадів температур і впливу вологи на електрообладнання та його елементи, наприклад: електродвигуни, трансформатори, комутаційні пристрої та з’єднання, радарні та передавальні установки, світлофори, елементи вуличного освітлення, запобіжники та запобіжні пристрої, будь-яке обладнання, що піддається впливу зовнішнього середовища та вологи.",
  },
  {
    name: "PL",
    photo: PL,
    properties:
      "Високоякісна синтетична проникаюча рідина. Одночасно змащує, розчиняє іржу, очищає, захищає від корозії, витісняє вологу. Має високу проникаючу здатність. 1літр PL забезпечує змочування поверхні площею 170 кв.м.",
    usage:
      "Застосовується для відвернення прикипілих і іржавих з’єднань, тимчасової консервації виробів у процесі їхнього виробництва. Змащення дрібних та високоточних деталей та сполучення, видалення вологи після обмивання та очищення деталей. Для очищення та змащення зброї, а також для обслуговування автомобілів.",
  },
  {
    name: "BC",
    photo: BC,
    properties:
      "Універсальний, концентрований, водорозчинний очищуючий засіб. Призначений для видалення всіх видів мастил, нафти продуктів, сажі, дьогтю, відкладень коптильних цехів, жирових та їм подібних забруднень з будь-яких поверхонь. Біорозкладається. Концентрат. Робочий розчин 5-15%",
    usage:
      "Застосовується в миючих машинах та мийках замкненого циклу для очищення механізмів, скла, алюмінію, шорстких, пофарбованих, оцинкованих та інших поверхонь. Застосовується під час миття автомобілів та знежирюванні металу. Застосовується на виробництві та у побуті. Очищувач дозволений до застосування в харчовій та фармацевтичній промисловості. Очищувач використовується у різних концентраціях залежно від ступеня забруднення. При необхідності допускається застосування концентрату.",
  },
];

export default products;
