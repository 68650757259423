import { useEffect, useRef } from 'react';
import anime from 'animejs';
import './Footer.scss';
import logo from './img/logo_ecofinex.png';
import { scrollTo } from '../utils/scrollTo';

function Footer() {
  const footerRef = useRef(null);
  const currentYear = new Date().getFullYear(); // Get the current year

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: '.Footer__logo img, .Footer__contact a, .Footer p',
              translateY: [500, 0],
              duration: 3000,
              easing: 'easeOutExpo',
              delay: anime.stagger(100),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <footer className="Footer" ref={footerRef}>
      <div className="Footer__logo" onClick={() => scrollTo('top')}>
        <img src={logo} alt="Logo" />
      </div>
      <div className="Footer__contact">
        <a href="tel:+380440000000">044 000 00 00</a>
        <p> &copy; 2023 - {currentYear}</p>
      </div>
    </footer>
  );
}

export default Footer;
