import { useEffect, useRef } from 'react';
import anime from 'animejs';
import React from 'react';
import './Eco.scss';
import eco from './img/eco.jpg';

function Eco() {
  const ecoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: '.Eco h1, .Eco h3',
              translateY: [500, 0],
              duration: 3000,
              easing: 'easeOutExpo',
              delay: anime.stagger(100),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (ecoRef.current) {
      observer.observe(ecoRef.current);
    }

    return () => {
      if (ecoRef.current) {
        observer.unobserve(ecoRef.current);
      }
    };
  }, []);
  return (
    <div
      className="Eco"
      style={{ backgroundImage: `url(${eco})` }}
      ref={ecoRef}
    >
      <div className="Eco__block">
        <h1>БЕЗПЕЧНО</h1>
        <div className="Eco__block__right">
          <h3>ДЛЯ ЛЮДИНИ</h3>
          <h3>ПРИРОДИ</h3>
          <h3>ОБЛАДНАННЯ</h3>
        </div>
      </div>
    </div>
  );
}

export default Eco;
