import { useEffect, useRef } from 'react';
import anime from 'animejs';
import React, { useState } from 'react';
import Button from '../components/Button';
import './Contact.scss';

const Contact = () => {
  const contactRef = useRef(null);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets:
                '.Contact label, .Contact input, .Contact textarea, .Contact button',
              translateY: [200, 0],
              duration: 2000,
              easing: 'easeOutExpo',
              delay: anime.stagger(50),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        observer.unobserve(contactRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission
  };

  const handlePhoneInputChange = (e) => {
    const value = e.target.value;
    // Регулярное выражение, которое соответствует только цифрам
    const re = /^[0-9\b]+$/;

    // Если значение пустое или соответствует регулярному выражению (только цифры), то обновляем состояние
    if (value === '' || re.test(value)) {
      setPhoneNumber(value);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="Contact"
      id="contact"
      ref={contactRef}
    >
      <label htmlFor="name"></label>
      <input
        type="text"
        id="name"
        placeholder="імʼя"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <label htmlFor="phoneNumber"></label>
      <input
        type="tel"
        id="phoneNumber"
        placeholder="номер телефону"
        value={phoneNumber}
        onChange={handlePhoneInputChange}
      />

      <label htmlFor="message"></label>
      <textarea
        id="message"
        placeholder="Повідомлення"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows={10}
      ></textarea>
      <Button buttonText="відправити" className="button" type="submit" />
    </form>
  );
};

export default Contact;
