import anime from "animejs";
import "./Use.scss";
import Button from "../components/Button";
import icon1 from "./img/icons/icon1.png";
import icon2 from "./img/icons/icon2.png";
import icon3 from "./img/icons/icon3.png";
import icon4 from "./img/icons/icon4.png";
import icon5 from "./img/icons/icon5.png";
import icon6 from "./img/icons/icon6.png";
import icon7 from "./img/icons/icon7.png";
import icon8 from "./img/icons/icon8.png";
import icon9 from "./img/icons/icon9.png";
import icon10 from "./img/icons/icon10.png";
import icon11 from "./img/icons/icon11.png";
import icon12 from "./img/icons/icon12.png";
import icon13 from "./img/icons/icon13.png";
import icon14 from "./img/icons/icon14.png";
import { scrollTo } from "../utils/scrollTo";
import { useEffect, useRef } from "react";

function Use() {
  const listRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets:
                ".Use__where h3, .Use__where li, .Use__what h3, .Use__what li, button",
              translateY: [100, 0],
              duration: 1000,
              easing: "easeOutExpo",
              delay: anime.stagger(50),
            });

            // Stop observing the element so the animation only happens once
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (listRef.current) {
      observer.observe(listRef.current);
    }

    return () => {
      if (listRef.current) {
        observer.unobserve(listRef.current);
      }
    };
  }, []);

  return (
    <div className="Use" ref={listRef}>
      <div className="Use__where">
        <h3>ДЕ ВИКОРИСТОВУЄТЬСЯ EcoFinex?</h3>

        <ul className="Use__where__list">
          <li>
            <img src={icon14} alt="Електроенергетика" />
            Електроенергетика
          </li>
          <li>
            <img src={icon1} alt="Нафтохімічна промисловість" />
            Нафтохімічна промисловість
          </li>
          <li>
            <img src={icon2} alt="Металургія та машинобудування" />
            Металургія та машинобудування
          </li>
          <li>
            <img src={icon3} alt="Промисловість будівельних матеріалів" />
            Промисловість будівельних матеріалів
          </li>
          <li>
            <img src={icon4} alt="Харчова промисловість" />
            Харчова промисловість
          </li>
          <li>
            <img src={icon5} alt="Легка промисловість" />
            Легка промисловість
          </li>
          <li>
            <img src={icon6} alt="Сільське господарство" />
            Сільське господарство
          </li>
          <li>
            <img src={icon7} alt="Авіація" />
            Авіація
          </li>
          <li>
            <img src={icon8} alt="Морський та річковий флот" />
            Морський та річковий флот
          </li>
          <li>
            <img src={icon9} alt="Залізничний транспорт" />
            Залізничний транспорт
          </li>
          <li>
            <img
              src={icon10}
              alt="Електрогосподарство будь-якого підприємства"
            />
            Електрогосподарство будь-якого підприємства
          </li>
          <li>
            <img src={icon11} alt="Міський електротранспорт" />
            Міський електротранспорт
          </li>
          <li>
            <img src={icon12} alt="Індустрія телекомунікацій" />
            Індустрія телекомунікацій
          </li>
          <li>
            <img src={icon13} alt="Метрополітен" />
            Метрополітен
          </li>
        </ul>
      </div>

      <div className="Use__what">
        <h3>Які заБРУДнення усуває ECOFINEX?</h3>

        <ul className="Use__what__list customList">
          <li>смола</li>
          <li>грибок</li>
          <li>корозія</li>
          <li>біологічні забруднення</li>
          <li>олійно-жирові відкладення</li>
          <li>вуглецеві відкладення</li>
          <li>дьоготь</li>
          <li>цемент</li>
          <li>фарби</li>
          <li>металевий пил</li>
          <li>пліснява</li>
          <li>різні клеї</li>
          <li>матеріали, що виробляються підприємством</li>
          <li>з’єднання важких металів</li>
          <li>оксидно-сульфідні плівки</li>
        </ul>
      </div>
      <Button
        buttonText="проконсультуватися"
        onClick={() => scrollTo("contact")}
      />
    </div>
  );
}

export default Use;
